module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"웹 프론트엔드 개발자의 성장 블로그","short_name":"웹 프론트엔드 개발자의 성장 블로그","start_url":"/","display":"standalone","icon":"src/assets/memoji.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f77bcaaa67d8de6938cfb4fbd6bdc34f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
